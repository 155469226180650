// Displays content from the collection/product metafield
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import { findMetafieldValue } from '../lib/product';
import MultiImageContentModule from './DynamicPage/MultiImageContentModule';

const DynamicShopifyMetafieldImagesModule = ({
  collection,
  product,
  blok: { fieldKey, fieldNamespace, fit, ...blokRest },
}) => {
  const source = collection || product;
  if (!source) return null;
  const fieldValue = findMetafieldValue(
    fieldKey,
    source.metafields,
    fieldNamespace
  );

  if (!fieldValue) {
    return null;
  }

  const images = fieldValue.map((x) => ({
    image: x,
    fit: fit,
    fill: true,
    options: { q: 75, w: 500, 'max-h': 200, auto: ['compress', 'format'] },
    alt: `${source.title}${x.altText ? ` - ${x.altText}` : ''}`,
    srcSetOptions: {
      q: 75,
      fit: 'crop',
      auto: ['compress', 'format'],
    },
  }));

  return (
    <Box fill>
      <MultiImageContentModule
        images={images}
        fit={fit}
        sizes="50vw, 100vw"
        paginationType={
          blokRest?.thumbnailPagination ? 'thumbnail' : blokRest?.paginationType
        }
        {...blokRest}
      />
    </Box>
  );
};

DynamicShopifyMetafieldImagesModule.propTypes = {
  blok: PropTypes.shape({
    fieldKey: PropTypes.string.isRequired,
    fieldNamespace: PropTypes.string,
    fit: PropTypes.oneOf(['cover', 'contain']),
  }),
  collection: PropTypes.object,
  product: PropTypes.object,
};

export default memo(DynamicShopifyMetafieldImagesModule);
