import React, { useContext, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, head, isEqual, sample } from '../../lib/nodash';
import { Box, ThemeContext } from 'grommet';
import loadable from '@loadable/component';
import styled from 'styled-components';

import useSwiperThumbnailPagination from '../useSwiperThumbnailPagination';
import isBlank from '../../lib/isBlank';

const DynamicImageContentModule = loadable(() =>
  import('./DynamicImageContentModule')
);
const ContentSlider = loadable(() => import('../ContentSlider'));

const Container = styled(Box)`
  .swiper-pagination {
    display: inherit;
  }
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    .swiper-pagination {
      display: none;
    }
  }
`;

const SliderBox = styled(Box)`
  position: ${(p) => (p.sticky ? 'sticky' : 'relative')};
  top: 0;
`;

const MultiImageContentModule = ({
  images,
  fit = 'cover',
  swiperParams = {},
  displayAs = 'slider',
  slidesPerView = 1,
  mobileSlidesPerView = 1,
  spaceBetween,
  navigation = false,
  navColor,
  loop = false,
  paginationType = 'thumbnail',
  autoHeight = false,
  sticky = false,
  sizes,
}) => {
  const theme = useContext(ThemeContext);
  const paginationRef = useRef(null);
  const pixelsBetween = spaceBetween
    ? parseInt(theme.global.edgeSize[spaceBetween])
    : 0;
  const singleImage = isEqual('oneRandom', displayAs)
    ? sample(images)
    : head(images);

  const thumbs = useSwiperThumbnailPagination(images, { fit });

  const pagination = useMemo(() => {
    if (paginationType === 'thumbnail') {
      return thumbs;
    }
    return {
      clickable: true,
      el: '.swiper-bullet-pagination',
    };
  }, [paginationType, thumbs]);

  const params = {
    ...swiperParams,
    slidesPerView: parseInt(
      isBlank(mobileSlidesPerView) ? 1 : mobileSlidesPerView
    ),
    spaceBetween: pixelsBetween,
    navigation,
    loop,
    pagination,
    autoHeight,
    breakpoints: {
      [theme.global.breakpoints.small.value]: {
        slidesPerView: parseInt(slidesPerView),
        spaceBetween: pixelsBetween,
        centeredSlides: false,
      },
    },
  };

  return (
    <Container fill>
      {images.length === 1 ? (
        <DynamicImageContentModule blok={singleImage} fit={fit} />
      ) : (
        <>
          {isEqual('oneRandom', displayAs) ? (
            <DynamicImageContentModule blok={singleImage} fit={fit} />
          ) : (
            <SliderBox sticky={sticky}>
              <ContentSlider
                params={params}
                navColor={navColor}
                swiperStyle={{ height: '100%', width: '100%' }}
              >
                {images.map((x) => {
                  return (
                    <DynamicImageContentModule
                      key={
                        get('image.id', x) ||
                        get('image.originalSrc', x) ||
                        x.id ||
                        x._uid ||
                        x.uuid ||
                        x.slug
                      }
                      blok={x}
                      fit={fit}
                      inSwiper={true}
                      sizes={sizes}
                      {...x}
                    />
                  );
                })}
              </ContentSlider>
              {(paginationType === 'bullet' ||
                paginationType === 'bullet-overlay') && (
                <div
                  ref={paginationRef}
                  className={`swiper-bullet-pagination ${
                    paginationType === 'bullet-overlay' ? 'overlay' : ''
                  }`}
                ></div>
              )}
            </SliderBox>
          )}
        </>
      )}
    </Container>
  );
};

MultiImageContentModule.propTypes = {
  images: PropTypes.array,
  fit: PropTypes.string,
  imgStyle: PropTypes.object,
  swiperParams: PropTypes.object,
  displayAs: PropTypes.string,
  slidesPerView: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileSlidesPerView: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  spaceBetween: PropTypes.oneOf([
    'xsmall',
    'small',
    'medium',
    'large',
    'xlarge',
  ]),
  navigation: PropTypes.bool,
  loop: PropTypes.bool,
  autoHeight: PropTypes.bool,
  navColor: PropTypes.string,
  thumbnailPagination: PropTypes.bool,
  paginationType: PropTypes.oneOf(['thumbnail', 'bullet']),
  sizes: PropTypes.string,
};

export default MultiImageContentModule;
